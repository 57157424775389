<template>
  <div class="app-breadcrumb">
    <el-breadcrumb separator="/">
      <!-- <el-breadcrumb-item v-for="(v,i) in list" :key="i">
        <span v-if="i==list.length-1">{{v.meta.title}}</span>
        <router-link :to="v.path" v-else>{{v.meta.title}}</router-link>
      </el-breadcrumb-item> -->
      <!-- 右侧下拉框 -->
    <div class="flex align-center justify-end notice">
      <el-badge :value="1" class="item">
        <i @click="showNoticModel('操作日志')" class="el-icon-document" style="cursor: pointer;"></i>
      </el-badge>
      <el-badge style="margin: 0 32px;" :value="2" class="item">
        <i @click="showNoticModel('通知')" class="el-icon-bell" style="cursor: pointer;"></i>
      </el-badge>
      <div class="flex align-center">
        <!-- <span>{{userAcc}}</span>
        <span style="margin: 0 5px;"></span>
        <span style="display: inline-block;cursor: pointer;" @click="logOut">退出</span> -->
        <el-dropdown>
          <span class="el-dropdown-link">
            <b>{{ userAcc }}</b>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <!-- <el-dropdown-item>修改</el-dropdown-item> -->
            <!--   若直接在el-dropdown-item上添加click事件，点击后没有任何反应。若在click后添加native修饰符，则可解决问题。 -->
            <el-dropdown-item @click.native="logOut">退出</el-dropdown-item>
          </el-dropdown-menu>
       </el-dropdown>
      </div>
    </div>
    <!-- <div class="right-menu">
      <el-dropdown>
        <span class="el-dropdown-link">
          退出<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>
            <span style="display:block;" @click="goBack">退出</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>-->
    </el-breadcrumb>
    <el-dialog id="noticModelbox" close="classNoticeDialogModel" :visible.sync="noticeDialogModel" :close-on-press-escape='false' :close-on-click-modal="false">
     <div class="padding-tb-16 solid-bottom">
      <div class="">
        <p class="text-14 text-gray padding-left-24">2021-02-02 15:36</p>
        <div class="flex align-center" style="margin-top: 8px;">
          <span class="margin-right-16" style="width: 8px;height: 8px;background: #FF1A2E;border-radius: 50%;"></span>
          <span class="text-14 text-black text-bold">愤怒的公鸡 发起了售后退款申请。</span>
        </div>
      </div>
     </div>
     <div class="padding-tb-16 solid-bottom">
      <div class="">
        <p class="text-14 text-gray padding-left-24">2021-02-02 15:36</p>
        <div class="flex align-center" style="margin-top: 8px;">
          <span :class="1<2?'read':''" class="margin-right-16" style="width: 8px;height: 8px;background: #FF1A2E;border-radius: 50%;"></span>
          <span class="text-14 text-black text-bold">愤怒的公鸡 发起了售后退款申请。</span>
        </div>
      </div>
     </div>

    <span slot="footer" class="dialog-footer">
    </span>
  </el-dialog>
  </div>
</template>

<script>
import {logList} from '@/api/http'
export default {
  name:'login',
  data(){
    return {
      userAcc:localStorage.getItem('yhc_admin_acc'),
      noticeDialogModel:false,
      logList:{
        id:localStorage.getItem('yhc_admin_token')
      }
    }
  },
  created(){
    this.getBreadcrumb();
    this.getLogList()
  },
  watch:{
    $route(to,from){
      this.getBreadcrumb();
    }
  },
  methods:{
    getLogList(){
      logList(this.logList).then(res=>{
        console.log('管理员日志:',res.data)
      })
    },
    closeNoticeDialogModel(){
      this.noticeDialogModel = false
    },
    getBreadcrumb(){
      this.list = this.$route.matched.filter(item=>item.meta && item.meta.title);
    },
    goBack(){
      // logout().then(res=>{
      //   console.log(res);
      //   let{code , data } = res.data;
      //   if(code == 20000){
      //     //删除token
      //     removeToken();
      //     this.$router.push('/login');
      //   }
        
      // })
      this.$store.dispatch('logout').then(res=>{
         removeToken();
         this.$router.push('/login');
      })
      
    },
    logOut(){
      this.$confirm('是否退出系统?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        localStorage.removeItem('yhc_admin_token')
        this.$router.push('/login')
        this.$message({
          type: 'success',
          message: '退出成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消成功'
        });
      });
    },
    showNoticModel(e){
      this.noticeDialogModel = true
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-breadcrumb {
      margin: 20px 10px;
      &> .right-menu{
          float: right;
      }
      .notice{
        padding-right: 16px;height: 30px;
      }
  }
</style>

