<template>
<el-main>
  <router-view/>
</el-main>
</template>

<script>

export default {
  name: 'Home',
  
}
</script>

