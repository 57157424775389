<template>
  <el-aside width="200px" class="aside">
    <!-- 左侧导航 -->
    <div class="logo">
      <img src="@/assets/img/logo.png" alt="" />
    </div>
    <div class="leftBar">
      <ul>
        <li class="p_item" v-for="(v, i) in navBar" :key="i" @click="toOtherPage(v.id)">
          <router-link :to="v.url" class="p_title flex align-center">
            <img :src="v.icon" />
            <span>{{ v.title }}</span>
          </router-link>
          <!-- 二级路由 -->
          <ul class="c_item">
            <li v-for="(s, j) in v.child" :key="j" @click="toOtherPage(v.id)">
              <router-link  :to="s.url" class="flex align-center" style="padding-left:45px;color:#fff;height:30px;line-height:30px">
                <img :src="s.icon" style="width: 16px;height: 16px;margin-right: 24px;"/>
                <span>{{ s.title }}</span>
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </el-aside>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      navBar: [],
      currentId:localStorage.getItem('yhc_left_currentitem')
    };
  },
  created() {
    this.navBar = this.$conf.navBar;
    console.log(this.navBar)
  },
  methods:{
    toOtherPage(id){
      console.log(id)
      this.currentId = id
      localStorage.setItem('yhc_left_currentitem',id)
    }
  }
};
</script>

<style lang="scss">
.aside {
  background-color: #345e3b;
  z-index: 2001;
  .leftBar,
  .el-menu-vertical-demo {
    height: 100%;
    background-color: #345e3b;
  }
  .leftBar ul {
    margin: 0;
    padding: 0;
    list-style: none;
    .p_item {
      .p_title {
        color: #fff;
        font-size: 14px;
        height: 56px;
        line-height: 56px;
        padding-left: 25px;
        cursor: pointer;
        img {
          width: 16px;
          height: 16px;
          margin-right: 24px;
        }
      }
    }
    .active {
      background-color: #0f2c14 !important;
    }
  }
  .logo {
    margin: 28px 13px 28px 11px;
    img {
      width: 100%;
    }
  }
  .el-menu {
    border: none;
    .el-menu-item {
      background-color: #345e3b;
    }
  }
}
</style>

