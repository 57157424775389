<template>
<div class="app-wrapper"> 
  <el-container>
      <!-- 左侧导航 -->
      <Sidebar />
    <el-container>
      <!-- 右侧头部 -->
      <el-header>
        <Header />
      </el-header>
      <!-- 右侧主体 -->
      <Main />
    </el-container>
  </el-container>
  </div>
</template>

<script>
// import Sidebar from './components/sidebar'
// import Header from './components/header'
// import Main from './components/main'
import {Sidebar, Header,Main} from './components'

export default {
  name:'login',
  data(){
    return {

    }
  },
  methods:{
    goBack(){
      this.$router.push('/login');
    }
  },
  components:{
    Sidebar,
    Header,
    Main
  }
}
</script>

<style lang="scss">
.app-wrapper,.el-container {
  height:100%;
  background-color: #F5F6FA;
  .el-header{
    background-color: #fff;
  }
  .el-main {
    background-color: #F5F6F7;
    color: #333;
    text-align: center;
    padding: 0;
  }
}

</style>